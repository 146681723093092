/* eslint-disable camelcase */

import { GetResult } from '@fingerprintjs/fingerprintjs';
import cloneDeep from 'lodash/cloneDeep';
import { Offer, OffersResponse, StartedOffer } from '../../components/offers/types';
import { RedemptionAvailability, RedemptionConfiguration, UserBalanceResponse } from '../../declarations/ah-api';
import { ReferralsEligibilityResultDto } from '../../declarations/interfaces/dtos/result/ReferralsEligibilityResultDto';
import i18n from '../../i18n';
import { AdminApiSurveyTheme } from '../../survey-tool/declarations/admin-api';
import {
  BROKEN_CAMPAIGN_REASON_CODE,
  InstanceInfo,
  PanelInfo,
  RatingPayload,
  UserReports,
} from '../../survey-tool/declarations/ah-api';
import { LoginResponse } from '../../survey-tool/declarations/auth-api';
import {
  CampaignCommon,
  CampaignDetail,
  CampaignGoResult,
  CampaignListing,
} from '../../survey-tool/declarations/campaign';
import { PartialSurveyToolStyleObject } from '../../survey-tool/default-styles/style-declaration';
import { adminApiSurveyThemeToSurveyToolStyleObject } from '../../survey-tool/default-styles/utils';
import { QuestionAnswer } from '../../survey-tool/survey-container';
import { browserNavigate } from '../../utils/browser-navigate';
import { redirectToCampaign } from '../../utils/redirects';
import { ensureProtocol } from '../../utils/uri';
import {
  ApiCall,
  ApiCallConfig,
  TokenHolder,
  UNDER_MAINTENANCE_STATUS_CODES,
  isClientErr,
  isServerErr,
} from './api-call';
import { AhApiInfo, getAhApiInfo } from './auth-api';

export interface TransactionsMonth {
  transaction: TransactionsMonthListing;
  transactionDetails?: TransactionListing[];
  loading?: boolean;
}

export interface TransactionsMonthListing {
  balance: number;
  balanceAtTheEndOfMonth: number;
  balanceAtTheStartOfMonth: number;
  earned: number;
  month: number;
  redeemed: number;
  transactions: number;
  year: number;
}

export interface TransactionsInfo {
  results: number;
  transactions: TransactionListing[];
}

export interface TransactionListing {
  amount: number;
  balanceAfterTransaction: number;
  balanceBeforeTransaction: number;
  campaignAdminId?: number;
  campaignPartnerId?: string;
  competition?: boolean;
  competitionTicketsForEntry?: number;
  date: string;
  fullDesc: string;
  isDenied?: boolean | null;
  isPending?: boolean | null;
  resolvedAt?: string | null;
  partnerApiComment?: string;
  subtype: TransactionSubtype;
  type: TransactionType;
  voucher?: string;
  expectedDateOfPayment?: string | null;
  uuid: string;
}

export enum TransactionType {
  CAMPAIGN = 'campaign',
  REDEEM = 'redeem',
  MANUAL = 'manual',
}

export type TransactionSubtype =
  | 'started'
  | 'screened'
  | 'skipped'
  | 'completed'
  | 'viewed'
  | 'shown_invite'
  | 'qualified'
  | 'quota_full'
  | 'manual'
  | 'apologies'
  | 'bank'
  | 'paypal'
  | 'hoyts'
  | 'voucher'
  | 'giftpay'
  | 'subscr'
  | 'migrated'
  | 'smartfuel'
  | 'referral'
  | 'disqualified'
  | 'debit'
  | 'goodwill'
  | 'acorns'
  | 'suppressed'
  | 'prezzee'
  | 'uniqgift'
  | 'truereward';

export async function AhApiCall<T>(tokenHolder: TokenHolder | null, cfg: ApiCallConfig) {
  if (tokenHolder?.ppToken) {
    cfg.headers ??= {};
    cfg.headers['pp-token'] = tokenHolder.ppToken;
  }
  return ApiCall<{ data: T }>(cfg);
}

const __ahApiCallCachePromise: Record<string, Promise<any> | null> = {};
function AhApiCallCached(loginResponse: LoginResponse | null, cfg: ApiCallConfig) {
  if (cfg.method.toUpperCase() !== 'GET') {
    // only GET methods can be cached
    return AhApiCall(loginResponse, cfg);
  }
  if (!__ahApiCallCachePromise[cfg.url]) {
    __ahApiCallCachePromise[cfg.url] = AhApiCall(loginResponse, cfg).catch((err) => {
      __ahApiCallCachePromise[cfg.url] = null;
      throw err;
    });
  }
  return __ahApiCallCachePromise[cfg.url]!.then((result) => cloneDeep(result.data));
}

type API_VERSION = 'v1' | 'v2' | 'v3';

function getBaseUrl(tokenHolder: TokenHolder, version: API_VERSION = 'v1') {
  if (version === 'v1' || version === 'v2') {
    return `${ensureProtocol(tokenHolder.instanceUrl)}/api/${version}/${tokenHolder.instanceCode}`;
  }
  return `${ensureProtocol(tokenHolder.instanceUrl)}/api/${version}`;
}

export const getAhApiBaseUrl = getBaseUrl;

export function getPublicUrl(ahApiInfo: AhApiInfo, version: API_VERSION = 'v1') {
  return `${ensureProtocol(ahApiInfo.ahApiHost)}/api/${version}/public`;
}

export async function getAhApiPing(loginResponse: LoginResponse) {
  const result = await AhApiCall(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse)}/ping`,
  });
  return result.data.data as {
    ppToken: string;
    ppUuid: string;
    instanceCode: string;
    instanceKey: string;
    instanceUrl: string;
  };
}

export async function getCompetitions(
  loginResponse: LoginResponse,
  init?: Record<string, string>
): Promise<TransactionsInfo['transactions']> {
  const searchParams = new URLSearchParams(init);
  searchParams.set('competitions', 'yes');
  const QUERY = searchParams.toString();

  const result = await AhApiCall<TransactionsInfo>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/transactions/paged${QUERY ? `?${QUERY}` : ''}`,
  });

  return result.data.data.transactions;
}

export async function getRedemptionTransactions(loginResponse: LoginResponse, init?: Record<string, string>) {
  const searchParams = new URLSearchParams(init);
  searchParams.set('redemptions', 'yes');
  const QUERY = searchParams.toString();

  const result = await AhApiCall<TransactionsInfo>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/transactions/paged${QUERY ? `?${QUERY}` : ''}`,
  });

  return result.data.data.transactions;
}

export async function getParticipations(loginResponse: LoginResponse, init?: Record<string, string>) {
  const searchParams = new URLSearchParams(init);
  const QUERY = searchParams.toString();

  const result = await AhApiCall<{ campaign: CampaignDetail; status: string }[]>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/participations/paged${QUERY ? `?${QUERY}` : ''}`,
  });

  return result.data.data;
}

export async function getReportedUuids(loginResponse: LoginResponse) {
  const result = await AhApiCall<UserReports['data']>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/user/reports`,
  });

  const { campaignReports, transactionReports } = result.data.data;

  return [
    campaignReports.map((campaignReport) => campaignReport.campaignUuid),
    transactionReports.map((transactionReport) => transactionReport.transactionUuid),
  ];
}

export async function getTransactions(
  loginResponse: LoginResponse,
  after: string,
  before: string,
  page: number,
  pageSize: number,
  includeRedemptions = false
) {
  let queryString = '';
  if (after) {
    queryString += `&after=${after}`;
  }
  if (before) {
    queryString += `&before=${before}`;
  }
  queryString += `&page=${page}`;

  let url = `${getBaseUrl(loginResponse, 'v3')}/transactions/paged/?pageSize=${pageSize}${queryString}&competitions=no`;

  if (!includeRedemptions) {
    url += `&redemptions=no`;
  }

  const result = await AhApiCall<TransactionsInfo>(loginResponse, { method: 'GET', url });
  return result.data.data;
}

export async function getTransactionsMonth(loginResponse: LoginResponse) {
  const result = await AhApiCall<any>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/transactions/full/?competitions=no`,
  });
  return result.data.data;
}

export async function getFixedBalanceTransfers(loginResponse: LoginResponse) {
  const result = await AhApiCall<{
    transactions: Array<{
      createdAt: string;
      amount: number;
    }>;
  }>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/transactions/fixed-balance-transfers`,
  });
  return result.data.data;
}

export async function getWelcomeCampaigns(loginResponse: LoginResponse) {
  try {
    const result = await AhApiCall(loginResponse, {
      method: 'GET',
      url: `${getBaseUrl(loginResponse)}/campaigns/welcome/?mode=list`,
    });
    return result.data.data as CampaignListing[];
  } catch (err) {
    if (isServerErr(err) && UNDER_MAINTENANCE_STATUS_CODES.includes(err.statusCode)) {
      // under maintenance
      return [];
    }
    throw err;
  }
}

export async function getReferralEligibility(loginResponse: LoginResponse) {
  const result = await AhApiCall<ReferralsEligibilityResultDto>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/user/profile`,
  });
  return result.data.data;
}

export interface IGetFeed {
  campaigns: CampaignListing[];
  page: number;
  pageSize: number;
  pages: number;
  results: number;
  surveyThemes?: { [surveyThemeUuid: string]: any };
}

export async function getFeed(loginResponse: LoginResponse, skip: string[], pageSize: number, filter: string) {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse, 'v3')}/campaigns/paged/?page=${
      skip.length === 0 ? '1' : 'next'
    }&pageSize=${pageSize}&filter=${filter}&mode=list&skipCount=${skip.length}`,
    data: { skip },
  });
  return result.data.data as IGetFeed;
}

export interface SubscriptionsResponse {
  data: Subscription[];
}

export interface Subscription {
  title: string;
  expires: string;
}

export async function getSubscriptions(loginResponse: LoginResponse): Promise<SubscriptionsResponse> {
  const result = await AhApiCall(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse)}/subscriptions`,
  });
  return result.data as SubscriptionsResponse;
}

export function isInvalidStart(err: any) {
  return isClientErr(err) && err.data?.code === 'invalid_start_go_to_welcome_campaign';
}

function checkInvalidStart(err: any): void {
  if (isInvalidStart(err)) {
    void redirectToCampaign(browserNavigate, err.data.campaignUuid as string);
  }
}

export async function getCampaignDetail(tokenHolder: TokenHolder, campaignUuid: string) {
  let result;
  try {
    result = await AhApiCall(tokenHolder, {
      method: 'GET',
      url: `${getBaseUrl(tokenHolder)}/campaigns/${campaignUuid}`,
    });
  } catch (err) {
    checkInvalidStart(err);
    throw err;
  }
  return result.data.data as CampaignDetail;
}

export async function getCampaignGoResult(tokenHolder: TokenHolder, campaignUuid: string) {
  let result;
  try {
    result = await AhApiCall(tokenHolder, {
      method: 'GET',
      url: `${getBaseUrl(tokenHolder)}/campaigns/${campaignUuid}/go`,
    });
  } catch (err) {
    checkInvalidStart(err);
    throw err;
  }
  return result.data.data as CampaignGoResult;
}

export function getCampaignFollowUrl(loginResponse: LoginResponse, campaignUuid: string) {
  let followUrl = `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/follow`;
  followUrl += `?pp-token=${loginResponse.ppToken}`;
  return followUrl;
}

export async function getCampaignTheme(
  tokenHolder: TokenHolder,
  campaignUuid: string
): Promise<PartialSurveyToolStyleObject> {
  const result = await AhApiCall(tokenHolder, {
    method: 'GET',
    url: `${getBaseUrl(tokenHolder)}/campaigns/${campaignUuid}/survey-theme`,
  });
  return adminApiSurveyThemeToSurveyToolStyleObject(result.data.data as AdminApiSurveyTheme | null);
}

export async function getSurveyThemeObj(panelInfo: PanelInfo, surveyThemeUuid: string) {
  const ahApiInfo = await getAhApiInfo({ panelUuid: panelInfo.guid });
  const url = `${getPublicUrl(ahApiInfo)}/survey-themes/${surveyThemeUuid}`;
  const result = (await AhApiCallCached(null, { method: 'GET', url })) as {
    data: AdminApiSurveyTheme | null;
  };
  return result.data;
}

export async function getSurveyTheme(
  panelInfo: PanelInfo,
  surveyThemeUuid: string
): Promise<PartialSurveyToolStyleObject> {
  const surveyThemeObj = await getSurveyThemeObj(panelInfo, surveyThemeUuid);
  return adminApiSurveyThemeToSurveyToolStyleObject(surveyThemeObj);
}

export async function submitAnswer(
  loginResponse: LoginResponse,
  campaignUuid: string,
  questionUuid: string,
  payload: QuestionAnswer
) {
  if (payload == null) {
    throw new Error(`answer payload can't be null!`);
  }
  const result = await AhApiCall<CampaignGoResult>(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/question/${questionUuid}`,
    data: payload,
  });
  return result.data.data;
}

export async function seenCampaign(loginResponse: LoginResponse, campaignUuid: string) {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/seen`,
  });
  return result.data.data;
}

export async function skipCampaign(loginResponse: LoginResponse, campaignUuid: string) {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/skip`,
  });
  return result.data.data;
}

export async function reportBrokenCampaign(
  loginResponse: LoginResponse,
  {
    campaignUuid,
    reasonCode,
    reasonText,
  }: {
    campaignUuid: string;
    reasonCode: BROKEN_CAMPAIGN_REASON_CODE;
    reasonText?: string | null;
  }
) {
  const result = await AhApiCall<CampaignCommon>(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/report-broken`,
    data: {
      reasonCode,
      reasonText,
    },
  });
  return result.data.data;
}

export async function reportBrokenTransaction(
  loginResponse: LoginResponse,
  {
    transactionUuid,
    reasonCode,
    reasonText,
  }: {
    transactionUuid: string;
    reasonCode: BROKEN_CAMPAIGN_REASON_CODE;
    reasonText?: string | null;
  }
) {
  const result = await AhApiCall<TransactionListing>(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse, 'v3')}/transactions/${transactionUuid}/report-broken`,
    data: {
      reasonCode,
      reasonText,
    },
  });
  return result.data.data;
}

export async function getUserBalance(loginResponse: LoginResponse) {
  const result = await AhApiCall<UserBalanceResponse>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/user/balance`,
  });
  return result.data.data;
}

function processInstanceInfoResult(instanceInfo: InstanceInfo) {
  // this service returns always exactly one panel so we can assume panelKey exists
  // const panelKey = Object.keys(instanceInfo.panels)[0];
  // const panelInfo = instanceInfo.panels[panelKey]!;
  return instanceInfo;
}

export async function getInstanceInfoByPanelUuid(panelUuid: string) {
  const ahApiInfo = await getAhApiInfo({ panelUuid });
  const result = await AhApiCall<InstanceInfo>(null, {
    method: 'GET',
    url: `${getPublicUrl(ahApiInfo)}/panel/${panelUuid}/instance-info`,
  });
  return processInstanceInfoResult(result.data.data);
}

export async function getRedemptions(loginResponse: LoginResponse) {
  const result = await AhApiCall<{ list: RedemptionConfiguration[] }>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v2')}/redemptions/settings`,
  });
  return result.data.data.list;
}

/**
 *
 * @param {LoginResponse} loginResponse
 * @param {string} redemptionGuid
 * @returns {Promise<RedemptionAvailability>}
 */
export async function getRedemptionAvailability(loginResponse: LoginResponse, redemptionGuid: string) {
  const result = await AhApiCall<RedemptionAvailability>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v2')}/redemptions/${redemptionGuid}/availability`,
  });
  return result.data.data;
}

export async function enterCompetition(loginResponse: LoginResponse, campaignUuid: string, competitionUuid: string) {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/enter-competition/${competitionUuid}`,
  });
  return result.data.data as CampaignGoResult;
}

export async function rejectCompetition(loginResponse: LoginResponse, campaignUuid: string) {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/reject-competition`,
  });
  return result.data.data as CampaignGoResult;
}

export async function getFeedback(loginResponse: LoginResponse, campaignId: string) {
  const result = await AhApiCall<RatingPayload | null>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignId}/rating`,
  });
  return result.data.data;
}

export async function submitFeedback(loginResponse: LoginResponse, campaignId: string, payload: RatingPayload) {
  // fix whitespace strings
  if (typeof payload.comment === 'string') {
    payload.comment = payload.comment.trim();
  }
  // fix empty strings
  if (!payload.comment) {
    payload.comment = undefined;
  }
  const result = await AhApiCall<void>(loginResponse, {
    method: 'POST',
    data: payload,
    url: `${getBaseUrl(loginResponse)}/campaigns/${campaignId}/rating`,
  });
  return result.data.data;
}

export interface RedemptionsRedeemPayload {
  redemptionGuid: string;
  optionGuid: string;
}

export async function redeem(loginResponse: LoginResponse, payload: RedemptionsRedeemPayload): Promise<any> {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    data: payload,
    url: `${getBaseUrl(loginResponse, 'v3')}/redemptions/redeem`,
  });
  return result.data.data;
}

export async function getIFrameQuestionContentUrl(
  loginResponse: LoginResponse,
  campaignUuid: string,
  questionUuid: string
): Promise<any> {
  const url = `${getBaseUrl(loginResponse)}/campaigns/${campaignUuid}/question/${questionUuid}/iframe?pp-token=${
    loginResponse.ppToken
  }&lang=${loginResponse.languageCode ?? i18n.language}`;
  return url;
}

export interface SubmitFingerprintPayload {
  visitorId: GetResult['visitorId'];
  components: GetResult['components'];
}

export async function submitFingerprint(loginResponse: LoginResponse, payload: SubmitFingerprintPayload) {
  const result = await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse, 'v3')}/user/fingerprint`,
    data: payload,
  });
  return result.data;
}

export interface OffersAndGamesTransaction {
  activity: string;
  task?: string;
  createdAt: string;
  reward: number;
  status: string;
}

export async function getOffersAndGames(
  loginResponse: LoginResponse,
  init?: Record<string, string>
): Promise<OffersAndGamesTransaction[]> {
  const searchParams = new URLSearchParams(init);
  const QUERY = searchParams.toString();
  const result = await AhApiCall<{
    rows: OffersAndGamesTransaction[];
  }>(loginResponse, {
    method: 'GET',
    url: `${getBaseUrl(loginResponse, 'v3')}/offers_and_games/paged${QUERY ? `?${QUERY}` : ''}`,
  });
  return result.data.data.rows;
}

/*
 * this fetch wrapper makes sure that a service is called at most once every *timeout* ms
 */
const fetchCachedCache: Record<string, any> = {};
function fetchCachedJson<T = any>(url: URL, requestInit: RequestInit, options: { timeout: number }): Promise<T> {
  const cacheKey = url.toString();
  if (fetchCachedCache[cacheKey]) {
    return fetchCachedCache[cacheKey] as Promise<T>;
  }

  const fetchPromise = new Promise<T>((resolve, reject) => {
    fetch(url, requestInit)
      .then((response) => response.json())
      .then((json) => resolve(json as T))
      .catch((err) => {
        reject(err);
      });
  });

  if (options.timeout) {
    fetchCachedCache[cacheKey] = fetchPromise;
    setTimeout(() => {
      delete fetchCachedCache[cacheKey];
    }, options.timeout);
  }

  return fetchPromise;
}

export async function getGames(
  loginResponse: LoginResponse,
  init?: string[][] | Record<string, string> | string | URLSearchParams
) {
  const params = new URLSearchParams(init);
  const url = new URL(`${getAhApiBaseUrl(loginResponse, 'v3')}/bitlabs/games?${params.toString()}`);
  const json = await fetchCachedJson<{ data: OffersResponse }>(
    url,
    {
      headers: { 'pp-token': loginResponse.ppToken },
    },
    { timeout: 5000 }
  );
  return json.data.data;
}

export async function getOffers(
  loginResponse: LoginResponse,
  init?: string[][] | Record<string, string> | string | URLSearchParams
) {
  const params = new URLSearchParams(init);
  const url = new URL(`${getAhApiBaseUrl(loginResponse, 'v3')}/bitlabs/offers?${params.toString()}`);
  const json = await fetchCachedJson<{ data: OffersResponse }>(
    url,
    {
      headers: { 'pp-token': loginResponse.ppToken },
    },
    { timeout: 5000 }
  );
  return json.data.data;
}

export async function viewOffer(
  loginResponse: LoginResponse,
  offer: Offer | StartedOffer
): Promise<Offer | StartedOffer> {
  await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse, 'v3')}/bitlabs/view-offer`,
    data: offer,
  });
  return offer;
}

export async function startOffer(
  loginResponse: LoginResponse,
  offer: Offer | StartedOffer
): Promise<Offer | StartedOffer> {
  await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse, 'v3')}/bitlabs/start-offer`,
    data: offer,
  });
  return offer;
}

export async function reportIssue(
  loginResponse: LoginResponse,
  offer: Offer | StartedOffer
): Promise<Offer | StartedOffer> {
  await AhApiCall(loginResponse, {
    method: 'POST',
    url: `${getBaseUrl(loginResponse, 'v3')}/bitlabs/report-issue`,
    data: offer,
  });
  return offer;
}
